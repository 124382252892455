import { Box, Flex, Group, Sx } from '@mantine/core'
import { ReactNode, useCallback } from 'react'

import { ThatchLink } from '~/components/shared/ThatchLink'
import { useScreenSize } from '~/hooks'

import { ThatchPill } from './ThatchPill'

import { Title } from '~/components/shared/text/Title'

const TagsList = ({
  title,
  containerSx,
  options,
  children,
  onClick,
  pillFill,
  pillFilled,
  pillHeight,
  prefetch=false
}: {
  title?: string
  options?: { label: string; path: string }[]
  containerSx?: Sx
  onClick?: (title: string) => void
  children?: ReactNode
  pillFill?: string
  pillFilled?: boolean
  pillHeight?: number
  prefetch?: boolean
}) => {
  const { isMobileScreen } = useScreenSize()

  const Tag = useCallback(
    ({ idx, label }: { idx: number; label: string }) => (
      <ThatchPill
        key={`option-${idx}`}
        size="sm"
        type="item"
        onClick={onClick ? () => onClick(title ?? "") : undefined}
        label={label}
        showChevronIcon
        filled={pillFilled ?? true}
        fill={pillFill}
        h={pillHeight}
      />
    ),
    []
  )

  return (
    <Flex
      direction="column"
      gap={32}
      sx={containerSx}
    >
      {title && (
        <Title variant='eyebrow' order={3}>{title}</Title>
      )}

      {options ? (
        <Group spacing={8}>
          {options.map((tag, idx) => (
            <Box key={`${tag}-${idx}`}>
              {onClick ? (
                <Tag
                  idx={idx}
                  label={tag.label}
                />
              ) : (
                <ThatchLink
                  to={tag.path ?? ''}
                  underline={false}
                  isOpenInNewWindow
                  prefetch={prefetch}
                >
                  <Tag
                    idx={idx}
                    label={tag.label}
                  />
                </ThatchLink>
              )}
            </Box>
          ))}
        </Group>
      ) : (
        children
      )}
    </Flex>
  )
}
export default TagsList
