import { Box, Divider, Flex, Sx } from '@mantine/core'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import { EmptyState } from '~/components/explore/EmptyState'
import { Typography } from '~/components/shared/text/Typography'
import { ProfileSummaryType } from '~/endpoints/model'
import { useScreenSize } from '~/hooks/useMobileScreen'

import { AvailableForHireModal } from '../seller/AvailableForHireModal'
import ProfileCard from '../seller/services-profile/ProfileCard'
import { ThatchButton } from '../shared/ThatchButton'
import { SellerCardPlaceholder } from './SellerCardPlaceholder'

export interface SellersContentProps {
  data: ProfileSummaryType[]
  onFetchData?: () => void
  isLoading?: boolean
  hasMore?: boolean
  wrapperStyles?: Sx
  emptyState?: any
  showAllSellersBtn?: boolean
}
export const SellersContent: React.FC<SellersContentProps> = props => {
  const { data, hasMore, onFetchData, isLoading } = props
  const router = useRouter()

  const [selectedSeller, setSelectedSeller] = useState<ProfileSummaryType | null>(null)

  const { isSmallScreen } = useScreenSize()

  const wrapperStyle = {
    maxWidth: 1400,
    margin: '0 auto',
    padding: `0 ${isSmallScreen ? 16 : 40}px`,
    ...props.wrapperStyles,
  }

  const onNext = async () => {
    onFetchData?.()
  }

  // Force re-render when contents are updated.
  // Need this since InfiniteScroll component keeps its scroll position
  // and we have no way to reset it's scroll position other than re-rendering it.
  const renderInfiniteScroll = useMemo(() => {
    return (
      <InfiniteScroll
        dataLength={data.length}
        style={{ overflowX: 'hidden' }}
        className="explore-infinite-scroll"
        next={onNext}
        hasMore={!!hasMore}
        loader={
          <Typography
            sx={wrapperStyle}
            align="center"
            mb={16}
          >
            Loading more experts...
          </Typography>
        }
      >
        <Flex
          direction="column"
          align={isSmallScreen ? 'center' : 'start'}
          sx={{ overflow: 'hidden', ...wrapperStyle }}
        >
          {data.map((item, idx) => (
            <Box
              key={`seller-${idx}`}
              w="100%"
              sx={{
                borderBottom: '1px solid rgba(0, 0, 0, 0.10)',
              }}
            >
              <ProfileCard
                key={`seller-${idx}`}
                data={item}
                sellerSearchView
              />
            </Box>
          ))}
          {props.showAllSellersBtn && (
            <ThatchButton
              label={<Link href="/sellers/all?page=1">All Travel Creators →</Link>}
              size="medium"
              sx={{ width: 'min-content', marginTop: 32, marginBottom: 2 }}
              variant="outline"
            />
          )}
        </Flex>

        {selectedSeller && (
          <AvailableForHireModal
            author={selectedSeller}
            onClose={() => setSelectedSeller(null)}
            saveCurrentStateToQuery
          />
        )}
      </InfiniteScroll>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  useEffect(() => {
    setSelectedSeller(data.find(item => item.username === router.query.seller) || null)
  }, [router.query.seller])

  return isLoading ? (
    <Flex
      gap={32}
      px={isSmallScreen ? 16 : 40}
      pt={32}
      pb={32}
      direction="column"
    >
      {Array.from(Array(12)).map((item, index) => (
        <Fragment key={`seller-${index}`}>
          <SellerCardPlaceholder />
          {index < 12 && <Divider />}
        </Fragment>
      ))}
    </Flex>
  ) : data.length > 0 ? (
    renderInfiniteScroll
  ) : props.emptyState !== undefined ? (
    props.emptyState
  ) : (
    <Box sx={wrapperStyle}>
      <EmptyState hideCreateGuide />
    </Box>
  )
}
